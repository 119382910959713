import FeminaPlusLogo from '@/femina/public/image/femina-plus-logo.svg';
import { StandaloneArticleHeader } from 'base/components/ArticleHeader';

export const FeminaStandaloneArticleHeader: typeof StandaloneArticleHeader = ({
  headline,
  isPaidContent,
  ...props
}) => {
  props.bylines?.forEach((byline) => {
    byline.image = '';
  });

  return (
    <StandaloneArticleHeader
      headline={
        <>
          {isPaidContent && (
            <div className="float-left mr-2 mt-1 h-6 w-6 sm:h-9.5 sm:w-9.5">
              <FeminaPlusLogo />
            </div>
          )}
          {headline}
        </>
      }
      {...props}
    />
  );
};
