import { tw } from '@/utils/tw';
import SuccessfulPaymentThemeBase from 'base/components/ArticlePaywall/SuccessfulPayment/theme';

const SuccessfulPaymentTheme = tw.theme({
  extend: SuccessfulPaymentThemeBase,
  slots: {
    headline: ['text-headline-md', 'md:text-headline-lg', 'mb-1.5', 'md:mb-2', 'text-center'],
    caption: ['text-content-sm', 'md:text-content-md', 'mb-4', 'md:mb-5', 'text-center'],
    description: ['text-content-sm', 'md:text-content-md', 'mb-3', 'md:mb-8'],
    steps: ['flex', 'flex-col', 'gap-3', 'md:gap-7', 'mb-6', 'md:mb-8'],
    step: ['text-content-heavy-sm', 'md:text-content-heavy-md', 'before:text-content-sm', 'md:before:text-content-md'],
    contactInfo: ['text-center', 'text-content-sm', '[&>a]:text-summer-red-600'],
  },
});

export default Object.assign(SuccessfulPaymentTheme, { Button: SuccessfulPaymentThemeBase.Button });
